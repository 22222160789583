import apiClient from "@/api/apiClient";

export const getProducts = async (filters) => {
  const result = await apiClient.get("/api/products", {
    params: {
      ...filters,
    },
  });
  return result.data;
};

export const getKaspiProducts = async (filters) => {
  const result = await apiClient.get("/api/kaspi/products", {
    params: {
      ...filters,
    },
  });
  return result.data;
};

export const getKaspiOnsaleProducts = async (filters) => {
  const result = await apiClient.get("/api/kaspi/onsale", {
    params: filters,
  });
  return result.data;
};

export const getOfferDetails = async (kaspiSku, cityId) => {
  const result = await apiClient.get(`/api/kaspi/offers/${kaspiSku}/${cityId}`);
  return result.data;
};

export const saveKaspiOnsaleProducts = async (payload) => {
  const result = await apiClient.post("/api/kaspi/onsale", payload);
  return result.data;
};

export const getAllProducts = async () => {
  const result = await apiClient.get("/api/products-all");
  return result.data;
};

export const getProduct = async (article) => {
  const result = await apiClient.get(`/api/product/${article}`);
  return result.data;
};

export const getKaspiProduct = async (id) => {
  const result = await apiClient.get(`/api/kaspi/products/${id}`);
  return result.data;
};

export const saveKaspiProductPrices = async (id, data) => {
  const result = await apiClient.post(`/api/kaspi/products/${id}`, data);
  return result.data;
};

export const createProduct = async (product) => {
  const result = await apiClient.post("/api/product/new", product);
  return result.data;
};

export const updateProduct = async (productId, product) => {
  const result = await apiClient.put(`/api/product/${productId}`, product);
  return result.data;
};

export const deleteProduct = async (productId) => {
  const result = await apiClient.delete(`/api/product/${productId}`);
  return result.data;
};

export const getProductModifications = async (productId) => {
  const result = await apiClient.get(`/api/product/${productId}/0`);
  return result.data.product_modifications;
};

export const searchProductModifications = async (kaspiSku) => {
  const result = await apiClient.get(`/api/product/modifications`, {
    params: {
      kaspi_sku: kaspiSku,
    },
  });
  return result.data.product_modifications;
};

export const createProductModification = async (productId, modification) => {
  const result = await apiClient.post(
    `/api/product/${productId}/new`,
    modification
  );
  return result.data;
};

export const deleteProductModification = async (productId, modification_id) => {
  const result = await apiClient.delete(
    `/api/product/${productId}/${modification_id}`
  );
  return result.data;
};

export const removeFromSale = async (ids) => {
  try {
    const result = await apiClient.post(`/api/products/group`, {
      action: "remove_from_sale",
      ids,
    });
    return {
      success: result.data.success,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
};

export const removeFromDamping = async (ids) => {
  try {
    const result = await apiClient.post(`/api/products/group`, {
      action: "remove_from_damping",
      ids,
    });
    return {
      success: result.data.success,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
};

//  Product preorder
export const getProductPreOrders = async (productId) => {
  const result = await apiClient.get(`/api/product/${productId}/pre_order`);
  return result.data;
};

export const createProductPreOrder = async ({
  product_id,
  city_id,
  pre_order,
}) => {
  const result = await apiClient.post(`/api/product/${product_id}/pre_order`, {
    city_id,
    pre_order,
  });
  return result.data;
};

export const removeProductPreOrder = async ({ product_id, city_id }) => {
  const result = await apiClient.post(`/api/product/${product_id}/pre_order`, {
    action: "delete",
    city_id,
  });
  return result.data;
};

export const createProductDamping = async ({ product_id, ...others }) => {
  const result = await apiClient.post(
    `/api/product/${product_id}/damping`,
    others
  );
  return result.data;
};

export const removeProductDamping = async ({ product_id, city_id }) => {
  const result = await apiClient.post(`/api/product/${product_id}/damping`, {
    action: "delete",
    city_id,
  });
  return result.data;
};
